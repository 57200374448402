@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
body {
  background-color: #f5f4f0;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  max-width:325px;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: inset 0 -2px 0 0 #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}
.MuiAppBar-root a,
a:visited {
  text-decoration: none;
  color: unset;
}

.Carousel .Indicators .Indicator.Active {
  color: #df8166 !important;
}

.Carousel .Indicators .Indicator {
  color: #2b292d2b !important;
  font-size: 12px !important;
}

@media only screen and (min-width: 768px) {
  .body_wrapper {
    padding-left: 100px;
    padding-right: 100px;
    z-index: 20;
    overflow: hidden;
  }

  .quoteUp{
    width:180px;
    height:130px;
    position: absolute;
    margin-left: -80px;
    margin-top: -80px;
  }

  .quoteDown{
    width:180px;
    height:130px;
    position: absolute;
    margin-left: 80%;
    margin-top: -25%;
  }

  .cookieConsent{
    right: 16px !important;
    left:unset !important;
    box-shadow: 0 2px 4px 0 #4a4a4a66;
    bottom: 16px !important;
    width:400px !important;
  }

}
/* Mobile */
@media only screen and (max-width: 767px) {
  .body_wrapper {
    z-index: -1;
    overflow: hidden;
    width:100%;
  }
  .quoteUp{
    width: 20%;
    margin-left: -10px;
    margin-top: -15px;
    position: absolute;
  }
  .quoteDown{
    width:20%;
    margin-left: 80%;
    margin-top:-15%;
    position: absolute;
  }

  .cookieConsent{
    right: 0px !important;
    box-shadow: 0 2px 4px 0 #4a4a4a66;
    bottom: 0px !important;
    width: 100%;
  }
}